import { graphql } from "gatsby";
import Img from "gatsby-image";
import * as React from "react";
import ContactForm from "../components/ContactForm";
import Layout from "../components/layout";
import theme from "../styles/theme";

const ContactPage = ({ location, data }) => {
  return (
    <Layout
      hideContact
      location={location}
      seo={data.page.seoMetaTags}
      logo={data.global.logo}
    >
      <main
        css={`
          margin: 0 auto;
          padding-bottom: 200px;
          width: 100%;
          max-width: 1600px;
          box-sizing: border-box;
          padding-top: 100px;
          ${theme.tablet} {
            padding-top: 40px;
          }
        `}
      >
        <section
          css={`
            margin-top: 100px;
            display: flex;
            flex-wrap: wrap;
            > div {
              box-sizing: border-box;
            }
            .formBox {
              flex-grow: 1;
              flex-basis: 0;
              flex-direction: column;
              box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
              border-radius: 10px;
              padding: 40px 40px;
              max-width: 550px;
            }
            .people {
              flex-grow: 1;
              flex-basis: 0;
              display: flex;
              flex-direction: column;
              padding-left: 40px;
              padding-top: 40px;
              h2 {
                font-size: 32px;
              }
              img {
                object-position: left !important;
              }
              .content {
                p:first-child {
                  margin-top: 16px;
                }
                p {
                  font-size: 16px;
                  line-height: 130%;
                  margin-top: 10px;
                }
              }
            }
          `}
        >
          <ContactForm data={data.page.formContent} />

          <div className="people">
            <h2>{data.page.peopleTitle}</h2>
            <div
              css={`
                display: grid;
                justify-content: center;
                grid-gap: 20px;
                grid-template-columns: repeat(3, 1fr);
                ${theme.max1300} {
                  grid-template-columns: repeat(2, 1fr);
                }
                ${theme.max600} {
                  grid-template-columns: repeat(1, 1fr);
                }
                > div {
                  flex: 1;
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  .img {
                    position: relative;
                    display: flex;
                    width: 100%;
                    max-height: 240px;
                  }
                  strong {
                    font-weight: 900;
                    font-size: 24px;
                  }
                  p {
                    font-size: 18px;
                    line-height: 150%;
                  }
                  ${theme.max900} {
                    > div h3 {
                      font-size: 28px;
                    }
                  }
                }
              `}
            >
              {data.page.people.map(item => {
                return (
                  <div>
                    <Img
                      className="img"
                      fluid={item.img.fluid}
                      alt={item.img.alt}
                      imgStyle={{
                        objectFit: "contain",
                      }}
                    />
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{ __html: item.content }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  query ContactQuery {
    page: datoCmsContact {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      formContent {
        value
      }
      peopleTitle
      people {
        img {
          fluid(maxWidth: 420) {
            ...GatsbyDatoCmsFluid
          }
        }
        content
      }
    }
    global: datoCmsGlobal {
      logo {
        fluid(maxWidth: 700) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
    }
  }
`;
